<template>
  <div class="login-container">
    <div class="banner">
      <div class="banner_img">
        <h2>{{ $t("L_LOGIN.LOGIN_TITLE") }}</h2>
      </div>
    </div>
    <div class="login-form">
      <div class="login-switch">
        <i
          class="type loginByCode"
          title="扫码登录"
          @click="handleLoginType(1)"
          v-if="loginBaseType === 2"
        ></i>
        <i
          class="type loginByAccount"
          title="账号登录"
          @click="handleLoginType(2)"
          v-if="loginBaseType === 1"
        ></i>
      </div>
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="rules"
        v-if="loginBaseType === 2 && loginType === 'pwd'"
      >
        <el-input type="text" class="autofill"></el-input>
        <el-input type="password" class="autofill"></el-input>
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="loginForm.username"
            :placeholder="$t('L_LOGIN.LOGIN_0001')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" class="password-item" :class="language === 'en' ? 'en':'zh'">
          <el-input
            type="password"
            ref="password"
            v-model="loginForm.password"
            :placeholder="$t('L_LOGIN.LOGIN_0002')"
            @keyup.enter.stop="handleLogin"
          ></el-input>
          <router-link to="/phoneVerification" tag="a">{{
              $t("L_LOGIN.BUTTON_0006")
            }}</router-link>
        </el-form-item>
        <el-form-item class="code-item" prop="code">
          <el-input
            type="text"
            v-model="loginForm.code"
            :placeholder="$t('L_LOGIN.LOGIN_0003')"
            @keyup.enter.stop="handleLogin"
          ></el-input>
          <img
            :src="codeUrl"
            @click="getCode"
            class="codeImg"
            :title="$t('L_LOGIN.LOGIN_0004')"
          />
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button
            type="primary"
            native-type="submit"
            :loading="logining"
            @click.native.prevent="handleLogin"
            >{{ $t("L_LOGIN.BUTTON_0001") }}</el-button
          >
        </el-form-item>
        <p class="register">
          <span @click="loginType = 'phone'">{{
            $t("L_LOGIN.BUTTON_0003")
          }}</span>
          <router-link style="float: right" to="/register" tag="span">{{
            $t("L_LOGIN.BUTTON_0002")
          }}</router-link>
        </p>
      </el-form>
      <el-form
        ref="loginForm2"
        :model="loginForm2"
        :rules="rules2"
        v-if="loginBaseType === 2 && loginType === 'phone'"
      >
        <el-input type="text" class="autofill"></el-input>
        <el-input type="password" class="autofill"></el-input>
        <el-form-item prop="phone">
          <el-input
            type="text"
            v-model="loginForm2.phone"
            :placeholder="$t('L_LOGIN.LOGIN_0005')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verificationCode" class="btn">
          <el-input
            type="text"
            autocomplete="new-password"
            v-model="loginForm2.verificationCode"
            :placeholder="$t('L_LOGIN.LOGIN_0003')"
          >
            <template slot="append">
              <el-button
                v-if="loginForm2.showloginCode"
                type="primary"
                @click="getloginPhoneCode"
                >{{ $t("L_LOGIN.BUTTON_0005") }}</el-button
              >
              <el-button v-else type="primary"
                >{{ loginForm2.count }}s</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button
            type="primary"
            native-type="submit"
            :loading="logining"
            @click.native.prevent="handleLogin2"
          >
            {{ $t("L_LOGIN.BUTTON_0001") }}
          </el-button>
        </el-form-item>
        <p class="register">
          <span @click="loginType = 'pwd'">{{
            $t("L_LOGIN.BUTTON_0004")
          }}</span>
          <router-link style="float: right" to="/register" tag="span">{{
            $t("L_LOGIN.BUTTON_0002")
          }}</router-link>
        </p>
      </el-form>
      <div v-if="loginBaseType === 1">
        <div id="weixin"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { moveToErr } from "@/utils/index";
import { setLocalStorage } from "@/utils/localstorage";
import {
  login,
  getCaptchaImage,
  loginByPhone,
  getCurrentUser,
} from "@/api/login";
import { sendMessageCode } from "@/api/register";
import md5 from "js-md5";
class WxLogin {
  constructor(e) {
    var r = "default";
    !0 === e.self_redirect
      ? (r = "true")
      : !1 === e.self_redirect && (r = "false");
    var n = document.createElement("iframe"),
      i =
        "https://open.weixin.qq.com/connect/qrconnect?appid=" +
        e.appid +
        "&scope=" +
        e.scope +
        "&redirect_uri=" +
        e.redirect_uri +
        "&state=" +
        e.state +
        "&login_type=jssdk&self_redirect=" +
        r +
        "&styletype=" +
        (e.styletype || "") +
        "&sizetype=" +
        (e.sizetype || "") +
        "&bgcolor=" +
        (e.bgcolor || "") +
        "&rst=" +
        (e.rst || "");
    (i += e.style ? "&style=" + e.style : ""),
      (i += e.href ? "&href=" + e.href : ""),
      (i += "en" === e.lang ? "&lang=en" : ""),
      (n.src = i),
      (n.frameBorder = "0"),
      (n.allowTransparency = "true"),
      (n.scrolling = "no"),
      (n.width = "300px"),
      (n.height = "400px");
    var s = document.getElementById(e.id);
    (s.innerHTML = ""), s.appendChild(n);
  }
}
export default {
  name: "login",
  data() {
    let validateUserName = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9\u4E00-\u9FA5_]{4,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_LOGIN.LOGIN_0001")));
      } else {
        return callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_LOGIN.LOGIN_0002")));
      } else {
        callback();
      }
    };
    let validatePhone = (rule, value, callback) => {
      let reg = /^((0\d{2,3}\d{7,8})|(1[358479]\d{9}))$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_LOGIN.LOGIN_0005")));
      } else {
        callback();
      }
    };
    let validateCode = (rule, value, callback) => {
      let reg = /^\d{6}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_LOGIN.LOGIN_0003")));
      } else {
        callback();
      }
    };
    return {
      codeUrl: "",
      // 表单参数
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: "",
        valitype: 1,
      },
      loginForm2: {
        phone: "",
        verificationCode: "",
        showloginCode: true,
        count: 60,
        timer: null,
      },
      rules: {
        username: [
          {
            validator: validateUserName,
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("L_LOGIN.LOGIN_0003"),
            trigger: "change",
          },
        ],
      },
      rules2: {
        phone: [
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        verificationCode: [{ validator: validateCode, trigger: "blur" }],
      },
      logining: false,
      loginType: "pwd",
      loginBaseType: 2
    };
  },
  beforeMount() {
    setLocalStorage("lkplat-v2-token", null, 24);
    setLocalStorage("larkview_userInfo", "", 24);
    setLocalStorage("larkview_userName", "", 24);
    setLocalStorage("larkview_loginStatus", false, 24);
    localStorage.setItem("userLogin", "");
    localStorage.setItem("userinfo", "")
  },
  mounted() {
    this.getCode();
  },
  computed: {
    language() {
      let language = this.$i18n.locale;
      return language;
    },
  },
  watch: {
    loginType(val) {
      if (val === "pwd") {
        this.resetForm1();
        this.resetForm2();
      } else {
        this.resetForm1();
        this.resetForm2();
      }
    },
  },
  methods: {
    getCode() {
      getCaptchaImage({}).then((res) => {
        if (res.code === 200) {
          this.codeUrl = "data:image/png;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    // 密码登录
    handleLogin() {
      this.handleStoreToken(null);
      this.handleStore("");
      this.logining = true;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let param = {
            username: this.loginForm.username.trim(),
            password: md5(this.loginForm.password).toUpperCase(),
            code: this.loginForm.code,
            uuid: this.loginForm.uuid,
            valitype: this.loginForm.valitype,
          };
          login(param)
            .then((response) => {
              if (response.code === 200) {
                this.handleStoreToken(response.token);
                getCurrentUser()
                  .then((res) => {
                    if (res.code === 200) {
                      this.logining = false;
                      let data = res.data;
                      this.handleStore(data);
                      let userLogin = JSON.stringify({
                        username: param.username,
                        password: param.password,
                        projid: 0,
                      });
                      localStorage.setItem("userLogin", userLogin);
                      this.$router.push({ path: "/" });
                    } else {
                      this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
                    }
                  })
                  .catch(() => {
                    this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
                  });
              } else {
                this.loginFailed(response.msg);
              }
            })
            .catch(() => {
              this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
            });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
          this.logining = false;
        }
      });
    },
    loginFailed(text) {
      this.logining = false;
      if (this.$refs.loginForm) {
        this.getCode();
      }
      this.resetForm1();
      this.resetForm2();
      this.handleMessaage(text, text);
    },
    handleMessaage(text1, text2) {
      this.$message({
        message: this.language === "en" ? text1 : text2,
        type: "error",
        offset: 60,
      });
    },
    //手机号登录
    handleLogin2() {
      this.handleStoreToken(null);
      this.handleStore("");
      this.logining = true;
      this.$refs.loginForm2.validate((valid) => {
        if (valid) {
          let param = {
            phone: this.loginForm2.phone,
            verifyCode: this.loginForm2.verificationCode,
          };
          loginByPhone(param)
            .then((response) => {
              if (response.code === 200) {
                this.handleStoreToken(response.token);
                getCurrentUser()
                  .then((res) => {
                    if (res.code === 200) {
                      this.logining = false;
                      let data = res.data;
                      this.handleStore(data);
                      let userLogin = JSON.stringify({ phone: param.phone });
                      localStorage.setItem("userLogin", userLogin);
                      this.$router.push({ path: "/" });
                    } else {
                      this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
                    }
                  })
                  .catch(() => {
                    this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
                  });
              } else {
                this.loginFailed(response.msg);
              }
            })
            .catch(() => {
              this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
            });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
          this.logining = false;
        }
      });
    },
    // 获取手机验证码
    getloginPhoneCode() {
      this.$refs.loginForm2.validateField("phone", async (valid) => {
        if (!valid) {
          // 获取验证码
          sendMessageCode({ phone: this.loginForm2.phone })
            .then((res) => {
              if (res.code !== 200) {
                this.handleMessaage(
                  "Operation failed, please try again later!",
                  "验证码发送失败！"
                );
              } else {
                // 当验证码发送成功，开始60秒倒计时
                if (!this.loginForm2.timer) {
                  this.loginForm2.showloginCode = false;
                  this.loginForm2.count = 60;
                  this.loginForm2.timer = setInterval(() => {
                    this.loginForm2.count--;
                    if (this.loginForm2.count <= 0) {
                      this.resetLoginForm();
                    }
                  }, 1000);
                } else {
                  this.resetLoginForm();
                }
              }
            })
            .catch(() => {
              this.handleMessaage(
                "Operation failed, please try again later!",
                "验证码发送失败！"
              );
            });
        } else {
          return false;
        }
      });
    },
    resetLoginForm() {
      if (!this.loginForm2.timer) {
        clearInterval(this.loginForm2.timer);
        this.loginForm2.timer = null;
      }
      this.loginForm2.showloginCode = true;
      this.loginForm2.count = 60;
    },
    resetForm1() {
      if (this.$refs.loginForm !== undefined) {
        this.loginForm = {
          username: "",
          password: "",
          code: "",
          uuid: "",
          valitype: 1,
        };
        this.$refs.loginForm.clearValidate();
      }
    },
    resetForm2() {
      if (this.$refs.loginForm2 !== undefined) {
        this.loginForm2 = {
          phone: "",
          verificationCode: "",
          showloginCode: true,
          count: 60,
          timer: null,
        };
        this.$refs.loginForm2.clearValidate();
      }
    },
    handleStore(info) {
      this.$store.commit("setUserInfo", info);
    },
    handleStoreToken(token) {
      this.$store.commit("setToken", token);
    },
    async handleLoginType(type) {
      this.loginBaseType = type;
      if (type === 1) {
        this.$nextTick(() => {
          this.setWxerwma();
        })

      }
    },
    setWxerwma() {
      this.handleStoreToken(null);
      var obj = new WxLogin({
        self_redirect: false,
        id: "weixin", // 要与你在那里生成二维码的div id 一致
        appid: "wxb185d67834cc5ded", // 申请的appid
        redirect_uri: encodeURIComponent(
          "https://www.larkview.cn/api/lkplat/v2/sys/wx/callback"
        ), // 回调url
        scope: "snsapi_login,snsapi_userinfo", // 权限
        state: "larkview", // 微信官方接口为了防止跨域攻击要加的参数

      });
    },
  },
  beforeDestroy() {
    clearInterval(this.loginForm2.timer);
    this.loginForm2.timer = null;
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/login";
</style>